

















































import Vue from 'vue'
import { UserReportInterface } from '@/types/models/UserReportInterface'
import { UserInterface } from '@/types/models/UserInterface'
import dataTableMixin from '@/mixins/dataTable'
import formattingMixin from '@/mixins/formatting'
import ActivityService from '@/services/activity'
import DebounceSearchComponent from '@/components/DebounceSearch.vue'

export default Vue.extend({
  name: 'ReportsPage',
  components: { DebounceSearchComponent },
  mixins: [dataTableMixin, formattingMixin],
  data: () => ({
    headers: [
      { text: 'User', value: 'user.username', sortable: false },
      { text: 'Entity', value: 'entity_name', sortable: false },
      { text: 'Action', value: 'action', sortable: false },
      { text: 'Date', value: 'date', sortable: false },
      { text: 'New value', value: 'new', sortable: false },
      { text: 'Old value', value: 'old', sortable: false }
    ],
    list: [] as UserReportInterface[],
    user: null as UserInterface | null,
    dialog: false,
    dialogImg: null as string | null
  }),
  methods: {
    getDataFromAPI () {
      const filters = this.prepareFilters()
      ActivityService.get(this.tableOptions, filters)
        .then(res => {
          if (res.data.body) {
            this.list = res.data.body.items ? res.data.body.items : []
            this.tableTotal = res.data.body.total ? res.data.body.total : 0
            this.pages = res.data.body.pages ? res.data.body.pages : 1
          }
        })
        .finally(() => {
          this.tableLoading = false
        })
    }
  }
})
